import React, { useCallback, useEffect, useRef, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import PropTypes from 'prop-types'
import useFetchCart from '../../../hooks/useFetchCart'
import useMenuData from '../../../hooks/useMenuData'
import HeaderSearchComponent from '../../Algolia/HeaderSearchComponent'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import AccountMenuItems from './AccountMenuItems'
import { pushToDataLayer } from '../../../utils/dataLayer'
import { usePopupState } from 'material-ui-popup-state/hooks'
import DesktopMenuItemPopover from './DesktopMenu/DesktopMenuItemPopover'
import { useDispatch, useSelector } from 'react-redux'
import { setCartDrawerOpen } from '../../../actions/cartDrawer'
import PromoBanner from './PromoBanner'
import PromoBannerCarousel from './PromoBannerCarousel'
import isNull from 'lodash/isNull'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import HeaderHelpCenter from './HeaderHelpCenter'

const useStyles = ({ disablePromotionBanner }) =>
    makeStyles((theme) => ({
        appBar: {
            backgroundColor: '#FFF',
        },
        toolBar: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
        },
        topMenuContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0 1.6rem',
            marginTop: '0.5rem',
            backgroundColor: 'inherit', // Override legacy SCSS styling
            boxShadow: 'inherit', // Override legacy SCSS styling
        },
        brandLogoImage: {
            width: 150,
            verticalAlign: 'middle',
            [theme.breakpoints.up('lg')]: {
                width: 320,
                marginRight: '1rem',
            },
        },
        iconLink: {
            cursor: 'pointer',
            margin: '0 0.5rem',
        },
        iconLinkContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 'max-content',
            '&:hover .iconLinkContainerIcon': {
                backgroundColor: '#3B1A6E',
                '&> img': {
                    filter: 'invert(94%) sepia(6%) saturate(0%) hue-rotate(104deg) brightness(106%) contrast(107%)',
                },
            },
        },
        iconLinkContainerIcon: {
            width: '30px',
            height: '30px',
            backgroundColor: '#EEEEEE',
            borderRadius: '25px',
            '&> img': {
                padding: '3px',
                filter: 'invert(16%) sepia(54%) saturate(2469%) hue-rotate(247deg) brightness(83%) contrast(105%)',
            },
        },
        iconLinkText: {
            color: '#3B1A6E',
            fontSize: '0.875rem',
        },
        bottomMenuContainer: {
            padding: '0 2rem',
            backgroundColor: '#3B1A6E',
            marginTop: '0.5rem',
            boxShadow: 'inherit', // Override legacy SCSS styling
        },
        bottomMenu: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0.5rem 0 0.5rem 0',
            alignItems: 'center',
            height: '2rem',
            [theme.breakpoints.only('md')]: {
                height: '1.4rem',
            },
        },
        link: {
            cursor: 'pointer',
            color: 'white',
            marginRight: '2rem',
            borderRadius: '20px',
            paddingLeft: '14px',
            paddingRight: '14px',
            '&:hover': {
                backgroundColor: 'white',
                color: '#422475',
                textDecoration: 'none',
            },
        },
        linkIcon: {
            borderRadius: '25px',
            backgroundColor: '#E8E8E9',
            width: '1.65rem',
            height: '1.65rem',
        },
        linkText: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem',
            whiteSpace: 'nowrap',
        },
        linkSubText: {
            fontSize: '1rem',
            color: '#878787',
        },
        menuDropdownButton: {
            borderWidth: '2px',
            textTransform: 'none',
            '&:hover': {
                borderWidth: '2px',
            },
        },
        menuDropdownButtonIcon: {
            verticalAlign: 'middle',
        },
        menuDropdownButtonText: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
        },
        popover: {
            pointerEvents: 'none',
        },
        popoverContent: {
            pointerEvents: 'auto',
            paddingTop: '1em',
            paddingBottom: '1em',
        },
        popoverBorder: {
            borderRadius: '0 0 25px 25px',
            borderTop: '12px solid  transparent',
            backgroundClip: 'padding-box',
            maxHeight: '70vh',
            [theme.breakpoints.up('xl')]: {
                maxHeight: '85vh',
            },
        },
        popoverGridRoot: {
            maxWidth: '22em',
        },
        popoverHeader: {
            color: 'rgb(126,86,184)',
            fontWeight: 'bold',
            paddingLeft: '1.5rem',
            fontSize: '1.25rem',
            marginTop: '0rem',
            marginBottom: 0,
            '@media only screen and (min-height: 700px)': {
                fontSize: '1.5rem',
                paddingLeft: '1.5rem!important',
            },
        },
        popoverGridItem: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            '&:first-of-type > h4': {
                paddingLeft: '1em',
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                left: '0',
                top: '28px',
                height: '92%',
                borderLeft: '1.5px solid #D7D7D7',
            },
        },
        popoverText: {
            color: '#7B8394',
        },
        popoverLink: {
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            margin: '5px 0 5px 0',
            color: '#36196C',
            textUnderlineOffset: '1px',
            paddingLeft: '.3em',
            width: '100%',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        popoverLinkText: {
            fontSize: '0.95rem',
        },
        popoverButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
        },
        popoverButtonIcon: {
            marginLeft: '5px',
            padding: '3px',
            width: '0.75em',
            height: '0.75em',
            borderRadius: '25px',
            backgroundColor: '#361570',
            color: 'white',
        },
        popoverListItemText: {
            fontSize: '0.95rem',
            paddingLeft: '1em',
            paddingRight: '1em',
        },
        searchWrapper: {
            flexGrow: 1,
            padding: '0 1em',
        },
        linksWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '30.35vw',
            maxWidth: '55vw',
            marginTop: '.25rem',
        },
        contactIcons: {
            verticalAlign: 'middle',
            paddingRight: '.15em',
        },
        promoBannerContainer: {
            minHeight: disablePromotionBanner ? '0' : '90px',
        },

        textUsPopUp: {
            background: 'white',
            color: 'black',
            padding: '10px',
            borderRadius: '5px',
            position: 'absolute',
            top: '5px',
            fontWeight: '600',
            width: '15rem',
            fontSize: '.9rem',
        },
    }))

const DesktopMenu = ({
    baseUrl,
    logoImage,
    setIsDropdownMenuOpen,
    setIsSearchDropdownOpen,
    isLoggedIn,
    firstName,
    disablePromotionBanner,
    extoleUserData,
}) => {
    const classes = useStyles({ disablePromotionBanner })()
    const headerMenuRef = useRef(null)
    const accountMenuPopupState = usePopupState({
        variant: 'popover',
        popupId: 'accountPopover',
    })
    const helpMenuPopupState = usePopupState({
        variant: 'popover',
        popupId: 'helpPopover',
    })
    useEffect(() => {
        setIsDropdownMenuOpen(accountMenuPopupState.isOpen)
    }, [accountMenuPopupState, setIsDropdownMenuOpen])
    useEffect(() => {
        setIsDropdownMenuOpen(helpMenuPopupState.isOpen)
    }, [helpMenuPopupState, setIsDropdownMenuOpen])
    const dispatch = useDispatch()
    const cartDrawerOpen = useSelector(
        (state) => state.cartDrawer.cartDrawerOpen
    )
    const toggleCartDrawerOpen = () =>
        dispatch(setCartDrawerOpen(!cartDrawerOpen))

    const { data: menuData } = useMenuData()

    const { data: cartResponse } = useFetchCart()
    const cartQuantity = cartResponse?.cart?.total_quantity
    const [promotionExpired, setPromotionExpired] = useState(false)
    const [coundDownVisibleScrollTop, setCoundDownVisibleScrollTop] =
        useState(true)

    const scrollHandler = useCallback(() => {
        let heightToHideFrom = 150
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll > heightToHideFrom) {
            coundDownVisibleScrollTop && setCoundDownVisibleScrollTop(false)
        } else {
            setCoundDownVisibleScrollTop(true)
        }
    }, [coundDownVisibleScrollTop, setCoundDownVisibleScrollTop])

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => window.removeEventListener('scroll', scrollHandler)
    }, [scrollHandler])

    /**
     * Set the header spacer div height so the homepage content appears below
     * the menu
     */
    useEffect(() => {
        if (!isNull(headerMenuRef?.current?.clientHeight)) {
            const headerHeight = headerMenuRef.current.clientHeight
            const headerSpacer = document.getElementById('header-spacer')
            if (!isNull(headerSpacer)) {
                headerSpacer.style.height = `${headerHeight}px`
            }
        }
    }, [headerMenuRef?.current?.clientHeight])

    return (
        <AppBar
            id="header-menu"
            ref={headerMenuRef}
            position="fixed"
            className={classes.appBar}
        >
            {!disablePromotionBanner &&
                coundDownVisibleScrollTop &&
                (menuData?.promoBanner &&
                menuData?.promoBanners?.length === 1 ? (
                    <div className={classes.promoBannerContainer}>
                        <PromoBanner
                            promoBanner={menuData.promoBanner}
                            setPromotionExpired={setPromotionExpired}
                        />
                    </div>
                ) : (
                    menuData?.promoBanners?.length > 1 && (
                        <PromoBannerCarousel
                            promoBanners={menuData.promoBanners}
                            setPromotionExpired={setPromotionExpired}
                        />
                    )
                ))}

            <Toolbar aria-label={'Main Menu'} className={classes.toolBar}>
                <Container
                    component={'nav'}
                    className={classes.topMenuContainer}
                    aria-label={'Card management and user menu'}
                    disableGutters={true}
                    maxWidth={false}
                >
                    <Box>
                        <a href={baseUrl} title="Back to homepage">
                            <img
                                className={classes.brandLogoImage}
                                src={logoImage}
                                alt={'Brand Logo'}
                            />
                        </a>
                    </Box>

                    <Box className={classes.searchWrapper}>
                        <HeaderSearchComponent
                            baseUrl={baseUrl}
                            setIsSearchDropdownOpen={setIsSearchDropdownOpen}
                        />
                    </Box>

                    <Box className={classes.linksWrapper}>
                        <PopupState variant="popover" popupId="helpPopover">
                            {() => (
                                <React.Fragment>
                                    <Link
                                        {...bindHover(helpMenuPopupState)}
                                        className={classes.iconLink}
                                        href={`https://help.giftcardgranny.com/hc/en-us`}
                                        title={'Help'}
                                        target={'_blank'}
                                    >
                                        <Box
                                            className={
                                                classes.iconLinkContainer
                                            }
                                            style={{ minWidth: '4.95rem' }}
                                        >
                                            <div
                                                className={`${classes.iconLinkContainerIcon} ${classes.iconLinkContainer} iconLinkContainerIcon`}
                                            >
                                                <img
                                                    alt="Question"
                                                    src={
                                                        '/top-bar/Question_Circle.svg'
                                                    }
                                                    width="30"
                                                    height="30"
                                                />
                                            </div>
                                            <Typography
                                                className={classes.iconLinkText}
                                                variant={'body2'}
                                            >
                                                Help Center
                                            </Typography>
                                        </Box>
                                    </Link>
                                    <HoverPopover
                                        {...bindPopover(helpMenuPopupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        className={classes.popover}
                                        classes={{
                                            paper: classes.popoverContent,
                                        }}
                                        transitionDuration={0}
                                        disableScrollLock={true}
                                    >
                                        <HeaderHelpCenter
                                            layout="desktop"
                                            baseUrl={baseUrl}
                                        />
                                    </HoverPopover>
                                </React.Fragment>
                            )}
                        </PopupState>

                        <Link
                            id={'activate-gift-card-link'}
                            href={`${baseUrl}/activate/`}
                            onClick={() =>
                                pushToDataLayer([
                                    'HeaderClick',
                                    'Header',
                                    'Click',
                                    'Activate A Card',
                                ])
                            }
                            title={'Activate Card'}
                            className={classes.iconLink}
                        >
                            <Box className={classes.iconLinkContainer}>
                                <div
                                    className={`${classes.iconLinkContainerIcon} ${classes.iconLinkContainer} iconLinkContainerIcon`}
                                >
                                    <img
                                        alt="Gift Card"
                                        src={'/top-bar/Gift_Card.svg'}
                                        width="30"
                                        height="30"
                                    />
                                </div>
                                <Typography
                                    className={classes.iconLinkText}
                                    variant={'body2'}
                                >
                                    Activate Card
                                </Typography>
                            </Box>
                        </Link>

                        <Link
                            id={'track-order-link'}
                            href={`${baseUrl}/orderstatus/`}
                            onClick={() =>
                                pushToDataLayer([
                                    'HeaderClick',
                                    'Header',
                                    'Click',
                                    'Track order',
                                ])
                            }
                            title={'Track Order'}
                            className={classes.iconLink}
                        >
                            <Box className={classes.iconLinkContainer}>
                                <div
                                    className={`${classes.iconLinkContainerIcon} ${classes.iconLinkContainer} iconLinkContainerIcon`}
                                >
                                    <img
                                        alt="Truck"
                                        src={'/top-bar/Truck.svg'}
                                        width="30"
                                        height="30"
                                    />
                                </div>
                                <Typography
                                    className={classes.iconLinkText}
                                    variant={'body2'}
                                >
                                    Track Order
                                </Typography>
                            </Box>
                        </Link>

                        <Link
                            id={'check-balance-link'}
                            href={`${baseUrl}/gift-card-balance-check/`}
                            onClick={() =>
                                pushToDataLayer([
                                    'HeaderClick',
                                    'Header',
                                    'Click',
                                    'Gift Card Balance',
                                ])
                            }
                            title={'Check Balance'}
                            className={classes.iconLink}
                        >
                            <Box className={classes.iconLinkContainer}>
                                <div
                                    className={`${classes.iconLinkContainerIcon} ${classes.iconLinkContainer} iconLinkContainerIcon`}
                                >
                                    <img
                                        alt="Money Bill"
                                        src={'/top-bar/Money_Bill.svg'}
                                        width="30"
                                        height="30"
                                    />
                                </div>
                                <Typography
                                    className={classes.iconLinkText}
                                    variant={'body2'}
                                >
                                    Check Balance
                                </Typography>
                            </Box>
                        </Link>
                        <PopupState variant="popover" popupId="accountPopover">
                            {() => (
                                <React.Fragment>
                                    <Link
                                        {...bindHover(accountMenuPopupState)}
                                        className={classes.iconLink}
                                        href={`${baseUrl}/account/`}
                                        title={'My account'}
                                    >
                                        <Box
                                            className={
                                                classes.iconLinkContainer
                                            }
                                            style={{ minWidth: '4.95rem' }}
                                        >
                                            <div
                                                className={`${classes.iconLinkContainerIcon} ${classes.iconLinkContainer} iconLinkContainerIcon`}
                                            >
                                                <img
                                                    alt="User"
                                                    src={'/top-bar/User.svg'}
                                                    width="30"
                                                    height="30"
                                                />
                                            </div>
                                            <Typography
                                                className={classes.iconLinkText}
                                                variant={'body2'}
                                            >
                                                {isLoggedIn
                                                    ? 'My account'
                                                    : 'Log in'}
                                            </Typography>
                                        </Box>
                                    </Link>
                                    <HoverPopover
                                        {...bindPopover(accountMenuPopupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        className={classes.popover}
                                        classes={{
                                            paper: classes.popoverContent,
                                        }}
                                        transitionDuration={0}
                                        disableScrollLock={true}
                                    >
                                        <AccountMenuItems
                                            isLoggedIn={isLoggedIn}
                                            firstName={firstName}
                                            baseUrl={baseUrl}
                                            extoleUserData={extoleUserData}
                                        />
                                    </HoverPopover>
                                </React.Fragment>
                            )}
                        </PopupState>
                        <Link
                            onClick={toggleCartDrawerOpen}
                            className={classes.iconLink}
                            id={'headerCartButton'}
                            title={'My cart'}
                        >
                            <Box className={classes.iconLinkContainer}>
                                <Badge
                                    badgeContent={cartQuantity}
                                    color="error"
                                >
                                    <div
                                        className={`${classes.iconLinkContainerIcon} ${classes.iconLinkContainer} iconLinkContainerIcon`}
                                    >
                                        <img
                                            alt="Shopping Cart"
                                            src={'/top-bar/Shopping_Cart.svg'}
                                            width="30"
                                            height="30"
                                        />
                                    </div>
                                </Badge>
                                <Typography
                                    className={classes.iconLinkText}
                                    variant={'body2'}
                                >
                                    View Cart
                                </Typography>
                            </Box>
                        </Link>
                    </Box>
                </Container>
                <Container
                    component={'nav'}
                    aria-label={'Buy cards and customer support menu'}
                    className={classes.bottomMenuContainer}
                    disableGutters={true}
                    maxWidth={false}
                >
                    <Box className={classes.bottomMenu}>
                        {menuData?.links &&
                            menuData?.links.map((item, index) => (
                                <DesktopMenuItemPopover
                                    item={item}
                                    classes={classes}
                                    setDarkenViewPort={setIsDropdownMenuOpen}
                                    popupId={'menuPopoverIndex' + index}
                                    key={'menuPopoverIndex' + index}
                                    extoleUserData={extoleUserData}
                                />
                            ))}
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>
    )
}

export default DesktopMenu

DesktopMenu.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    logoImage: PropTypes.string.isRequired,
    setIsDropdownMenuOpen: PropTypes.func.isRequired,
    setIsSearchDropdownOpen: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
}
