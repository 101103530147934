import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const primaryColor = 'rgb(89,32,99)'
const secondaryColor = 'rgb(0,92,89)'
const secondaryColorLight = '#01AEA4'
const black = '#222222'
const white = '#FFFFFF'
const grey = '#e0e0e0'
const darkestGrey = '#4F4F4F'
const darkGrey = '#868686'
const lightGrey = '#D3D3D3'
const veryLightGrey = '#F5F5F5'
const errorColor = '#B71C1C'
const yellow = '#F8D759'

const baseSiteTheme = createMuiTheme({
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
            light: secondaryColorLight,
        },
        black,
        white,
        lightGrey: {
            main: lightGrey,
            secondary: veryLightGrey,
        },
        grey: {
            main: grey,
            darkestGrey: darkestGrey,
        },
        yellow: {
            main: yellow,
        },
        error: {
            main: errorColor,
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                '&::-webkit-input-placeholder': {
                    color: darkGrey,
                    opacity: 1,
                },
                '&::-moz-input-placeholder': {
                    color: darkGrey,
                    opacity: 1,
                },
                '&::-ms-input-placeholder': {
                    color: darkGrey,
                    opacity: 1,
                },
            },
        },
        MuiButton: {
            root: {
                transition: 'color .01s',
            },
            outlinedPrimary: {
                borderWidth: '2px',
                '&:hover': {
                    borderWidth: '2px',
                },
            },
        },
        MuiSelect: {
            selectMenu: {
                whiteSpace: 'pre-wrap',
            },
        },
    },
})

export const rootStyles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            display: 'block',
            maxWidth: '100%',
        },
    },
}

export default baseSiteTheme
